Drupal.behaviors.SDSearchGnavFormatterV1 = (function ($) {
  var $searchBlock = $();
  var $searchIcon = $();
  var $activeScreenWrapper = $();
  var $searchTags = $();
  var targetNode;
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
  var behavior = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      $searchBlock = $('.gnav-header-block__search', context);
      $searchIcon = $('.js-sd-search-gnav-input-field', context);

      $searchTags = $('.js-gnav-search__tags-block', context);
      targetNode = $searchBlock.find('.js-sd-search-gnav-input-field')[0];

      var observer = new MutationObserver(function (mutations) {
        var length = mutations.length;

        for (var i = 0; i < length; i++) {
          var mutation = mutations[i];

          if (
            mutation.addedNodes.length > 0 &&
            $(mutation.addedNodes[0]).hasClass('js-search-speak-wrapper')
          ) {
            $searchTags.addClass('elc-voice-search-overlay-displayed');
          }
          if (
            mutation.removedNodes.length > 0 &&
            $(mutation.removedNodes[0]).hasClass('js-search-speak-wrapper')
          ) {
            $searchTags.removeClass('elc-voice-search-overlay-displayed');
          }
        }
      });

      function searchIconHandler() {
        $('.optanon-alert-box-close').click();
        $activeScreenWrapper = $('.js-search-active-screen-wrapper', context);
        if (!$activeScreenWrapper.length) {
          if (!isMobile) {
            $searchIcon.find('.js-gnav-search-button').click();
          }
        } else {
          if (isMobile) {
            observer.observe(targetNode, {
              childList: true,
              subtree: true,
              attributes: false,
              characterData: false
            });
          }
        }
      }

      if (!isMobile) {
        $searchIcon.on('mouseenter', '.js-gnav-search-button', _.debounce(searchIconHandler));
      } else {
        $searchIcon.once().on('click', function () {
          searchIconHandler();
        });
        var $searchActive = $('.js-search-active-screen-wrapper', context);

        if ($searchTags.length > 0) {
          $(document).trigger('search_tags_active', [$searchTags, $searchActive]);
        }
      }
    }
  };

  return behavior;
})(jQuery);
